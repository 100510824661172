
import { defineComponent, Ref, ref, computed } from 'vue';
import { useMutation, useQuery } from '@vue/apollo-composable';
import dayjs from 'dayjs';

import { user } from '@/composable/auth';
import { copyValue } from '@/utils/emitter';
import { commafy, formatDate } from '@/utils/formatter';
import { promoteQuery } from '@/apollo/queries';
import { UserPromoteRcoinRewardVM } from '@/view-models/promote.vm';
import { promoteMutation } from '@/apollo/mutations';
import { useActivitySetting } from '@/composable/setting';
import { Dialog, Toast } from 'vant';
// import { UserVM } from '@/view-models/auth.vm';

export default defineComponent({
  setup() {
    const input: Ref<HTMLInputElement | null> = ref(null);

    const { rewardRcoinActivitySetting } = useActivitySetting();

    const { result, loading: isLoading } = useQuery<{ userPromoteRcoinRewards: UserPromoteRcoinRewardVM[] }>(
      promoteQuery.getUserPromoteRcoinRewards,
    );
    const userPromoteRcoinRewards = computed(() => result.value?.userPromoteRcoinRewards ?? []);

    const totalConsumedCount = computed(
      () =>
        useQuery<{ promoteStatusDashboard: any }>(promoteQuery.getPromoteStatusDashboard, {
          param: {
            inviteeType: null,
            dateStartInviteeRegistered: null,
            dateEndInviteeRegistered: null,
          },
        }).result.value?.promoteStatusDashboard ?? 0,
    );

    const { mutate: userReceiveRewardMutate, loading: isReceiveLoading } = useMutation(
      promoteMutation.userReceiveReward,
    );

    // const { mutate: updateUserPromoteCodeMutate, loading: isUpdateLoading } = useMutation<{
    //   updateUserPromoteCode: UserVM;
    // }>(promoteMutation.updateUserPromoteCode, {
    //   refetchQueries: ['getUser'],
    // });

    function getButtonText({
      dateReachedTarget,
      isAbleToReceive,
      isTodayReceived,
      receivedCount,
      maxReceiveCount,
      dateReceivedStart,
      dateReceivedEnd,
    }: UserPromoteRcoinRewardVM) {
      switch (true) {
        case !dateReachedTarget:
          return `尚未達標`;
        case receivedCount >= maxReceiveCount:
          return '領取完畢';
        case dayjs().isAfter(dateReceivedEnd):
          return '已過期';
        case dayjs().isBefore(dateReceivedStart):
          return `${formatDate(dateReceivedStart, false)} 起可以領取`;
        case dateReachedTarget && !isAbleToReceive:
        case isAbleToReceive:
          return `${isTodayReceived ? '今日已領取' : '領取'}（${receivedCount}/${maxReceiveCount}）`;
      }
    }

    async function userReceiveReward({ id, promoteRcoinAmount }: UserPromoteRcoinRewardVM) {
      if (!rewardRcoinActivitySetting.value?.currentEnabled) return;

      try {
        await Dialog.confirm({
          title: `折價券 ${promoteRcoinAmount} 元`,
          message: `確定要領取嗎？`,
          confirmButtonText: '確定領取',
        });

        await userReceiveRewardMutate({
          userPromoteRcoinRewardId: id,
        });

        Toast({
          type: 'success',
          message: `已領取折價券 ${promoteRcoinAmount} 元`,
        });
      } catch (err) {
        console.log(err);
      }
    }

    return {
      user,
      userPromoteRcoinRewards,
      isLoading,
      isReceiveLoading,
      totalConsumedCount,
      rewardRcoinActivitySetting,
      input,

      copyValue,
      commafy,
      formatDate,
      getButtonText,
      userReceiveReward,
    };
  },
});
